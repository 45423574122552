.bar-divver {
    position: fixed;
    flex-direction: column;
    top: 220px;
    left: -100vw;
    right: 0;
    width: 300vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}
.bar-divver p {
    cursor: pointer;
    z-index: 4;
    margin-top: 4px;
    color: #bbc7d8;
    font-size: 12px;
    text-decoration: underline;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
   }
    to {
        transform: rotate(359deg);
   }
}
.Bar {
    z-index: 3;
    background-color: #0067f9;
    transition: 800ms;
    height: 130px;
    width: 300vw;
    transform: rotate(0deg);
    transform-origin: center;
    border-top-right-radius: 65px;
    border-bottom-right-radius: 65px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0px;
}
.Bar .loader {
    transition: 800ms;
    z-index: 100;
    border: 10px solid #fff;
    border-right-color: #fff;
    border-top-color: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    margin-right: 15px;
    height: 80px;
    width: 80px;
    animation: spin 800ms infinite linear;
}
.Bar-hidden {
    margin-top: -600px;
    transition: 0px;
}

@keyframes loading-move {
    0% {
        margin-top: 0px;
    }
    100% {
        margin-top: -5px;
   }
}
.Bar-loading {
    width: 80vw;
    margin-left: -20vw;
    margin-top: 0px;
    animation: loading-move 700ms infinite ease-in-out alternate 800ms;
}
@keyframes success-bar-key {
    0% {
        width: 80vw;
        margin-left: -20vw;
   }
    30% {
        width: 300vw;
        margin-left: 0;
   }
    100% {
        width: 130px;
   }
}
@keyframes success-circle-key {
    0% {
        stroke-dashoffset: 600;
        opacity: 1;
   }
    100% {
        stroke-dashoffset: 0;
        opacity: 1;
   }
}
@keyframes success-check-key {
    0% {
        stroke-dashoffset: -124;
   }
    100% {
        stroke-dashoffset: 0;
   }
}
.Bar-done {
    border-radius: 85px;
    transform: rotate(0deg);
    animation: success-bar-key 1000ms cubic-bezier(0.62, 0.13, 0, 0.82);
    animation-fill-mode: forwards;
}
.Bar-done .loader {
    border-color: rgba(255, 255, 255, 1);
}
.Bar-done svg polyline {
    stroke-dasharray: 124;
    stroke-dashoffset: -124;
    animation: success-check-key 250ms 1200ms cubic-bezier(1, 0.16, 0, 0.93);
    animation-fill-mode: forwards;
}
.Bar-done svg circle {
    opacity: 0;
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    transform-origin: center;
    transform: rotate(327deg);
    animation: success-circle-key 400ms 1650ms cubic-bezier(0.8, 0.28, 0.2, 0.76);
    animation-fill-mode: forwards;
}
@keyframes success-loader-disappear {
    0% {
        opacity: 1;
        display: block;
   }
    100% {
        opacity: 0;
        display: none;
   }
}
.loader-disappear {
    animation: success-loader-disappear 500ms;
    display: none;
    opacity: 0;
}
