@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
}

body, html {
    overflow: hidden;
    min-height: 100vh;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}